import moment from 'moment';
export const isBrowser = () => typeof window !== 'undefined';

const CURRENT_DAY = Number(moment().utc().format('DD'));
let START_DATE = null;

if (CURRENT_DAY <= 18) {
	START_DATE = moment().utc().format('YYYY-MM-DD');
} else {
	START_DATE = moment().add(1, 'month').utc().format('YYYY-MM-DD');
}

const INITIAL_PAST_BATCH = moment(START_DATE)
	.subtract(2, 'months')
	.startOf('month')
	.add(18, 'days')
	.format('MMM-DD-YYYY');

const FINISH_PAST_BATCH = moment(START_DATE)
	.subtract(1, 'months')
	.startOf('month')
	.add(17, 'days')
	.format('MMM-DD-YYYY');

const INITIAL_CURRENT_BATCH = moment(START_DATE)
	.subtract(1, 'months')
	.startOf('month')
	.add(18, 'days')
	.format('MMM-DD-YYYY');

const FINISH_CURRENT_BATCH = moment(START_DATE).startOf('month').add(17, 'days').format('MMM-DD-YYYY');
const INITIAL_NEXT_BATCH = moment(START_DATE).startOf('month').add(18, 'days').format('MMM-DD-YYYY');
const FINISH_NEXT_BATCH = moment(START_DATE).add(1, 'months').startOf('month').add(17, 'days').format('MMM-DD-YYYY');

export const FILTER_BATCHES = [
	{ text: 'Show All', value: '' },
	{
		text: `Previous: ${INITIAL_PAST_BATCH} - ${FINISH_PAST_BATCH}`,
		value: 'PAST'
	},
	{
		text: `Current: ${INITIAL_CURRENT_BATCH} - ${FINISH_CURRENT_BATCH}`,
		value: 'CURRENT'
	},
	{
		text: `Next: ${INITIAL_NEXT_BATCH} - ${FINISH_NEXT_BATCH}`,
		value: 'NEXT'
	}
];

export const BOX_STATUSES = [
	{ text: 'Unshipped', value: 'UNSHIPPED' },
	{ text: 'Shipped', value: 'SHIPPED' },
	{ text: 'Cancelled', value: 'CANCELLED' },
	{ text: 'Donated', value: 'DONATED' },
	{ text: 'Show All', value: '' }
];

export const BOX_STATUSES_SIMPLE = [
	{ text: 'Unshipped', value: 'UNSHIPPED' },
	{ text: 'Shipped', value: 'SHIPPED' }
];

export const FEATURE_STATUSES = {
	FILLED: 'FILLED',
	EMPTY: 'EMPTY',
	SKIPPED: 'SKIPPED'
};

export const SUBSCRIPTION_STATUSES = [
	{ text: 'Active', value: 'ACTIVE' },
	{ text: 'Cancelled', value: 'CANCELLED' },
	{ text: 'Incomplete', value: 'INCOMPLETE' },
	{ text: 'Show All', value: '' }
];

export const GRANDMA = 'grandma';
export const GRANDPA = 'grandpa';
export const GRANDPARENTS = 'grandparents';

export const YES_NOT_OPTIONS = [
	{
		text: 'Yes',
		value: true
	},
	{
		text: 'No',
		value: false
	},
	{
		text: 'Show All',
		value: ''
	}
];

export const CUSTOM_CLAIMS = ['Admins', 'BackOfficeAdmins', 'BackOfficeOperators', 'Developers'];

export const ROLES = {
	ADMINS: 'Admins',
	SUPPORT: 'BackOfficeAdmins',
	OPERATOR: 'BackOfficeOperators',
	DEVELOPERS: 'Developers'
};

export const ROLES_OPTIONS = [
	{ value: 'Admins', text: 'Administrator' },
	{ value: 'Developers', text: 'Developer' },
	{ value: 'BackOfficeAdmins', text: 'Support' },
	{ value: 'BackOfficeOperators', text: 'Package' },
	{ value: 'Users', text: 'Unauthorized' }
];

export const PRODUCTS_TYPES = [
	{
		key: 1,
		text: 'Emergency Basic Box',
		value: ['emergency', 'basic']
	},
	{
		key: 2,
		text: 'Emergency Premium Box',
		value: ['emergency', 'premium']
	},
	{
		key: 3,
		text: 'Outdoor Basic Box',
		value: ['outdoor', 'basic']
	},
	{
		key: 4,
		text: 'Outdoor Premium Box',
		value: ['outdoor', 'premium']
	},
	{
		key: 5,
		text: 'Show All',
		value: ''
	}
];

export const getBatchNumbers = () => {
	const batchCounter = 10000;
	const initialStartBatch = '2020/12/18';
	const initialFinishBatch = '2021/01/19';
	const batchesList = [];

	for (let i = 0; i < batchCounter; i++) {
		const initBatch = moment(initialStartBatch)
			.add(i, 'months')
			.startOf('month')
			.add(18, 'days')
			.utc()
			.format('MMM-DD-YYYY');
		const finishBatch = moment(initialFinishBatch)
			.add(i, 'months')
			.startOf('month')
			.add(17, 'days')
			.utc()
			.format('MMM-DD-YYYY');
		const batchNumber = i + 1;

		const batchRange = `${initBatch} - ${finishBatch} `;

		const batchData = {
			batchNumber: batchNumber,
			batchRange: batchRange
		};

		batchesList.push(batchData);
	}

	return batchesList;
};
