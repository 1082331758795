export const isJsonString = (jsonData) => {
	if (
		/^[\],:{}\s]*$/.test(
			jsonData
				.replace(/\["\\\/bfnrtu]/g, '@')
				.replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+]?\d+)?/g, ']')
				.replace(/(?:^|:|,)(?:\s*\[)+/g, '')
		)
	) {
		return true;
	}
	return false;
};

export const validateInputFilled = (inputValue) => {
	if (inputValue && inputValue.length > 1) {
		return true;
	}
	return false;
};

export const validateInputEmail = (email) => {
	const regExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	const isValidEmail = regExp.test(String(email).toLowerCase());
	if (isValidEmail) {
		return true;
	}
	return false;
};

export const stringCapitalization = (string) => {
	const newString = string.charAt(0).toUpperCase() + string.slice(1);

	return newString.replace(/([a-z])([A-Z])/g, '$1 $2');
};

export const phoneNumberValidator = (phoneNumber) => {
	const phoneRegEx = /^[\s+]?(1\s?)?[\s-]?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/g;
	if (phoneNumber.match(phoneRegEx)) {
		return true;
	}
	return false;
};

export const getFormattedPhoneNumber = (phoneNumber) => {
	var cleaned = ('' + phoneNumber).replace(/\D/g, '');
	var shortMatch = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
	const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

	if (shortMatch) {
		return `+1 (${shortMatch[1]})-${shortMatch[2]}-${shortMatch[3]}`;
	}

	if (match) {
		return `+${match[1]} (${match[2]})-${match[3]}-${match[4]}`;
	}

	return '';
};

export const getDataFilters = (formState) => {
	const formStateKeys = Object.keys(formState);
	let inputData = {};

	formStateKeys.forEach((key) => {
		const value = formState[key];

		if (value || value === false) {
			inputData[key] = value;
		}
	});

	return {
		...inputData
	};
};
