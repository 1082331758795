import moment from 'moment';
import { isJsonString } from './dataValidationService';

export const setCookie = (cookieName, cookieData) => {
	const today = moment();
	const tomorrow = moment(today).add(1, 'days');
	const formattedExpirationDate = new Date(tomorrow);
	const expires = 'expires=' + formattedExpirationDate.toGMTString();

	document.cookie = cookieName + '=' + encodeURI(JSON.stringify(cookieData)) + ';' + expires + ';path=/';
};

export const getCookie = (cookieName) => {
	var name = cookieName + '=';
	var decodedCookie = decodeURIComponent(document.cookie);
	var entries = decodedCookie.split(';');
	for (var i = 0; i < entries.length; i++) {
		var entry = entries[i];
		while (entry.charAt(0) === ' ') {
			entry = entry.substring(1);
		}
		if (entry.indexOf(name) === 0) {
			try {
				const jsonData = decodeURI(entry.substring(name.length, entry.length));
				const jsonVerified = isJsonString(jsonData);
				if (jsonVerified) return JSON.parse(jsonData);
				return false;
			} catch (e) {
				return false;
			}
		}
	}
};

export const deleteCookie = (cookieName) => {
	var deletedCookieDate = 'expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
	document.cookie = `${cookieName}=;${deletedCookieDate}`;
};
