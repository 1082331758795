import firebase from 'gatsby-plugin-firebase';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { setCookie, getCookie, deleteCookie } from './cookiesService';
import { removeLocalStorageItem } from './localStorageService';
import { USER_DATA, CUSTOM_TABLE_SORTS, BOX_LIST_FILTERS, SUBSCRIPTION_LIST_FILTERS } from '../constants/localStorage';
import { CUSTOM_CLAIMS } from '../constants/universal';
import { isBrowser } from '../constants/universal';

const auth = firebase.auth;

auth &&
	auth().onAuthStateChanged(async (user) => {
		if (user) {
			const tokenId = await user.getIdToken();

			console.log(tokenId);

			if (tokenId) {
				const expirationDate = tokenId && new Date(jwtDecode(tokenId).exp * 1000);
				const isValidToken = moment().isBefore(expirationDate);

				if (isValidToken) {
					setCookie(USER_DATA, tokenId);
				} else {
					const tokenId = await auth().currentUser.getIdToken(true);
					setCookie(USER_DATA, tokenId);
				}
			}
		}
	});

export const signInWithGmail = async () => {
	const provider = new auth.GoogleAuthProvider();

	try {
		const user = await auth().signInWithPopup(provider);
		if (user) {
			const tokenId = await auth().currentUser.getIdToken();
			setCookie(USER_DATA, tokenId);

			return true;
		}
	} catch (error) {
		return error.message;
	}
};

export const getIdToken = async () => {
	const tokenId = (await auth().currentUser) && auth().currentUser.getIdToken();

	if (tokenId) {
		setCookie(USER_DATA, tokenId);
		return tokenId;
	}
};

export const getUser = () => {
	const user = isBrowser() && getCookie(USER_DATA);

	return user;
};

export const isLoggedIn = async () => {
	const user = await getUser();
	let decodedUser = null;

	if (user) {
		decodedUser = await jwtDecode(user);
	}
	return decodedUser;
};

export const signOut = async () => {
	try {
		await auth().signOut();
		deleteCookie(USER_DATA);
		removeLocalStorageItem(CUSTOM_TABLE_SORTS);
		removeLocalStorageItem(BOX_LIST_FILTERS);
		removeLocalStorageItem(SUBSCRIPTION_LIST_FILTERS);

		if (isBrowser()) window.location.replace(window.location.origin);
	} catch (error) {}
};

export const checkIfAuthrized = (user) => {
	const checkIfOneWay = user.email.includes('onewayecommerce.com') || user.email.includes('softdev.team') || user.email.includes('elasticdevteam.com');;

	let roleAuthorized = false;

	if (checkIfOneWay) {
		roleAuthorized = user.roles.some((role) => {
			return CUSTOM_CLAIMS.some((authRole) => authRole === role);
		});
	}

	return roleAuthorized;
};

export const uploadFiles = async (data) => {
	const storageRef = firebase.storage().ref();
	const noteSaved = [];
	const photoSaved = [];
	let errorUploading = null;

	const files = data.files;

	for (const file of files) {
		try {
			const childRef = storageRef.child(`grandbox/${data.ownerId}/${data.boxId}/${file.name}`);
			const uploadTask = await childRef.putString(file.encodeString, 'base64', file.metadata);

			console.log(uploadTask);

			if (uploadTask.state === 'success') {
				const url = await childRef.getDownloadURL();

				if (file.extention === 'pdf') {
					noteSaved.push(url);
				} else {
					photoSaved.push(url);
				}
			}
		} catch (error) {
			errorUploading = error;
		}
	}

	const uploadResponse = {
		filesSaved: {
			photos: photoSaved,
			note: noteSaved
		},
		error: errorUploading
	};
	return uploadResponse;
};
